import React from 'react';
import { Section, Wrapper, Col } from './footer.css';
import FugYep from '../../../static/FugYep.svg';
import MusicCares from '../../../static/musicCares.png';
import MusicSupport from '../../../static/footer-musicSupport.png';
import SocialIcon from './SocialIcon';
import Instagram from '../../../static/instagram.svg';
import Twitter from '../../../static/twitter.svg';
import Facebook from '../../../static/facebook.svg';

const Footer = () => (
  <Section>
    <Wrapper>
      <Col icons>
        <img src={FugYep} alt="Fug Yep" />
        <img src={MusicCares} className="musicCare" alt="Music Cares" />
        <img src={MusicSupport} alt="Music Support" />
      </Col>

      <Col social>
        <div className="social-wrapper">
          <div className="icons">
            <SocialIcon
              icon={Instagram}
              url="https://www.instagram.com/fugyep/"
              desc="Fug Yep on Instagram"
            />
            <SocialIcon
              icon={Twitter}
              url="https://twitter.com/FugYep"
              desc="Fug Yep on Twitter"
            />
            <SocialIcon
              icon={Facebook}
              url="https://www.facebook.com/FugYep"
              desc="Fug Yep on Facebook"
            />
          </div>

          <form
            action="https://fugyep.us19.list-manage.com/subscribe/post?u=8b9ae4833351e2f62ab611abd&amp;id=91b8daa69d"
            method="post"
          >
            <input
              required
              className="inputField"
              type="email"
              name="EMAIL"
              placeholder="Enter Email"
            />
            <input
              type="submit"
              className="inputBtn"
              name="subscribe"
              value="join"
            />
          </form>
        </div>
      </Col>
    </Wrapper>
  </Section>
);

export default Footer;
