import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';

export const Wrapper = styled.div`
  padding: 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  ${({ pink }) =>
    pink &&
    css`
      background-color: ${colors.pink};
    `} @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  font-family: 'ConfidentialPro';
  font-size: 2.2em;
  display: block;
  margin: 1.2em auto;
  text-align: center;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

export const Col = styled.div`
  width: calc(100% / 2 - 2em);

  ${({ center }) =>
    center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `} @media (max-width: 768px) {
    width: 100%;
  }

  h3 {
    font-family: 'ConfidentialPro';
    font-size: 2em;
    display: block;
    margin-bottom: 0.6em;
  }

  p {
    line-height: 1.6;
    margin-bottom: 2em;
  }
`;

export const Info = styled.div`
  text-align: center;
  padding-bottom: 2em;

  ul {
    margin-top: 3em;
    column-count: 2;

    @media (max-width: 760px) {
      column-count: 1;
    }
  }

  li {
    margin-bottom: 20px;
    color: ${colors.black};
    font-size: 1.5em;
    line-height: 1.2;
  }

  img {
    margin-bottom: 25px;
    margin-top: 15px;
    width: 150px;
    transform: scale(0.8);

    &.MCLogo {
      width: 320px;
    }
  }

  span {
    font-weight: 700;
  }
`;

export const Donate = styled.div`
  a {
    color: ${colors.white};
    text-transform: uppercase;
    margin-right: 1em;
    display: inline-block;
    margin-bottom: 2em;
    font-size: 0.9em;
    font-weight: 700;
    transition: color, background-color 130ms ease-out;

    &:last-child {
      margin-right: 0;
    }

    &.email {
      text-decoration: none;
      margin-bottom: 1px;
      border-bottom: 1px solid ${colors.black};
      &:hover {
        border-bottom-color: ${colors.white};
      }
    }

    &.btn {
      padding: 10px 16px;
      background-color: ${colors.white};
      color: ${colors.black};
      text-decoration: none;
      border-radius: 25px;
      border: 1px solid ${colors.white};

      &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
      }
    }
  }
`;

export const SupportBlock = styled.div`
  background: ${colors.black};
  padding: 3em 0;
  text-align: center;
  a {
    &.bigbtn {
      display: inline-block;
      color: ${colors.white};
      text-decoration: none;
      padding: 1em 2em;
      border: 1px solid ${colors.white};
      transition: color, background-color 130ms ease-out;
      &:hover {
        color: ${colors.black};
        background-color: ${colors.pink};
        border-color: ${colors.pink};
      }
    }
  }
`;
