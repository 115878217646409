import React from 'react';
import { Wrapper, Col, Info, Donate, Title, SupportBlock } from './middle.css';
import MusicSupport from '../../../static/footer-musicSupport.svg';
import MusicCares from '../../../static/musicCares.png';

const Middle = () => (
  <React.Fragment>
    <Title>Organizations</Title>
    <Wrapper>
      <Col>
        <Info>
          <img className="MCLogo" src={MusicCares} alt="Music Cares" />
          <p>
            A friend and ally of the music community, <span>MusiCares</span> was
            established by the Recording Academy to safeguard the health and
            well-being of all music people. A four-star charity and safety net
            in times of need, MusiCares offers confidential preventative,
            recovery, and emergency programs to address financial, medical, and
            personal health issues. Through the generosity of our donors and
            volunteer professionals, our dedicated team works across the country
            to ensure the music community has the resources and support it
            needs.
          </p>

          <Donate>
            <a
              className="btn"
              href="https://www.grammy.com/musicares/donations"
            >
              Donate
            </a>
            <a
              className="email"
              href="https://www.grammy.com/musicares/about/who-we-are-contact-information"
            >
              contact
            </a>
          </Donate>
        </Info>
      </Col>

      <Col>
        <Info>
          <img src={MusicSupport} alt="Music Cares" />
          <p>
            <span>Music Support</span> is founded and run by people from the UK
            music industry, for individuals suffering from mental, emotional and
            behavioral health disorders (including but not limited to alcohol
            and drug addiction).
          </p>
          <Donate>
            <a className="btn" href="https://www.musicsupport.org/">
              Donate
            </a>
            <a className="email" href="http://www.musicsupport.org/contactus">
              contact
            </a>
          </Donate>
        </Info>
      </Col>
    </Wrapper>

    <div
      style={{
        backgroundColor: '#d56e70',
        padding: ' .5em 0 1em 0',
        marginBottom: 0,
      }}
    >
      <Col center>
        <Title color="#000">Artists</Title>

        <Info>
          <ul>
            <li>Dan Auerbach</li>
            <li>David Bazan</li>
            <li>Jessie Baylin</li>
            <li>Fruit Bats</li>
            <li>Gardens and Villa</li>
            <li>Damien Jurado</li>
            <li>Lucius</li>
            <li>James Mercer</li>
            <li>Pure Bathing Culture</li>
            <li>Jonathan Rado</li>
            <li>
              Nathaniel Rateliff and
              <br /> the Night Sweats
            </li>
          </ul>
        </Info>
      </Col>
    </div>
    <SupportBlock>
      <a href="mailto:fugyep@gmail.com" className="bigbtn">
        Looking for support?
      </a>
    </SupportBlock>
  </React.Fragment>
);

export default Middle;
