import React from 'react';
import Layout from 'components/layout';
import Intro from 'components/intro';
import Top from 'components/top';
import Middle from 'components/middle';
import Bottom from 'components/bottom';
import Footer from 'components/footer';

const Index = () => (
  <Layout>
    <Intro />
    <Top />
    <Middle />
    <Bottom />
    <Footer />
  </Layout>
);

export default Index;
