import styled from 'styled-components';
import { colors } from '../../constants/theme';

export const Section = styled.div`
  background-color: #fff;
`;

export const Wrapper = styled.div`
  padding: 6rem 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: ${colors.black};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Prods = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Product = styled.div`
  width: calc(100% / 3 - 30px);
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    background: transparent url(${({ img }) => img}) no-repeat;
    background-size: contain;
    width: 100%;
    padding-bottom: 150%;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .info {
    h3 {
      font-family: 'ConfidentialPro';
      font-size: 1.7em;
      margin-bottom: 0.6em;
    }

    p {
      line-height: 1.6;
      margin-bottom: 2em;
    }
  }

  .btn {
    display: inline-block;
    margin-right: 20px;
    border: 2px solid ${colors.black};
    align-self: baseline;
    color: ${colors.black};
    padding: 1em 2em;
    font-size: 0.9em;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    transition: color, background-color 130ms ease-out;
    &:hover {
      color: ${colors.pink};
      background-color: ${colors.black};
    }
  }
`;
