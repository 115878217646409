import React from 'react';
import { Section, Wrapper, Prods, Product, Details } from './bottom.css';
import Shirt1 from '../../../static/white-tee.png';
import Shirt2 from '../../../static/black-tee.png';
import Mug from '../../../static/mug.png';

const Bottom = () => (
  <Section>
    <Wrapper>
      <Prods>
        <Product img={Shirt1} />
        <Product img={Shirt2} />
        <Product img={Mug} />
      </Prods>

      <Details>
        <div className="info">
          <h3>100% of profits support our partners</h3>
        </div>
        <a href="https://ilovelucius.store/collections/fug-yep" className="btn">
          BUY FUG YEP STUFF
        </a>
      </Details>
    </Wrapper>
  </Section>
);

export default Bottom;
