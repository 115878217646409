import React from 'react';
import Youtube from 'react-youtube-embed';
import {
  Header,
  Box,
  Content,
  Info,
  Album,
  Release,
  Num,
  VidWrap,
} from './top.css';
import No1 from '../../../static/record-rosy.png';
import No2 from '../../../static/fugyep-2.png';
import No3 from '../../../static/fugyep-3.png';

const Top = () => (
  <Header>
    <Box>
      <Release>
        <h3>Releases</h3>
      </Release>
      <Content>
        <Info>
          <Num>No. 3</Num>
          <h2>Nathaniel Rateliff &amp; The Night Sweats</h2>

          <div className="preorderInfo">
            {"Don't Care Darlin"}
            <br />
            B/W Hot Dog
          </div>

          <div
            className="preorderDetails"
            style={{ marginBottom: '15px', marginTop: '10px' }}
          >
            <strong>
              <a
                href="https://ffm.to/fugyep3"
                target="_blank"
                rel="noopener noreferrer"
                className="singleLine"
              >
                Listen Now
              </a>
            </strong>
          </div>

          <div className="preorderDetails">
            Written by Nathaniel Rateliff
            <br />
            Mixed by Richard Swift and Patrick Meese
            <br />
            Produced by Richard Swift
          </div>

          <div
            className="preorderDetails"
            style={{ marginBottom: '15px', marginTop: '10px' }}
          >
            {/* <strong>
              <a
                className="listenLink"
                href="https://ffm.to/fugyep2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Listen Now
              </a>
            </strong> */}
          </div>

          {/* <div className="preorderDetails">
            <strong>Out Now</strong>
          </div> */}

          <a
            style={{ marginTop: '10px' }}
            href="https://ilovelucius.store/collections/fug-yep/products/fug-yep-soundation-no-3-7-inch-vinyl"
          >
            ORDER 7”
          </a>

          <p className="artwork">All Artwork by Richard Swift</p>
        </Info>
        <Album source={No3} />
      </Content>
      <Content>
        <Info>
          <Num>No. 2</Num>
          <h2>The Shins</h2>

          <div className="preorderInfo">
            Waimanalo
            <br />
            B/W Trapped By The Sea
          </div>

          <div
            className="preorderDetails"
            style={{ marginBottom: '15px', marginTop: '10px' }}
          >
            <strong>
              <a
                href="https://ffm.to/fugyep2"
                target="_blank"
                rel="noopener noreferrer"
                className="singleLine"
              >
                Listen Now
              </a>
            </strong>
          </div>

          <div className="preorderDetails">
            Written and Produced by James Mercer.
            <br />
            Inspired by Richard Swift
          </div>

          <div
            className="preorderDetails"
            style={{ marginBottom: '15px', marginTop: '10px' }}
          >
            <strong>
              <a
                href="https://ffm.to/fugyep2"
                rel="noopener noreferrer"
                target="_blank"
                className="singleLine"
              >
                Listen Now
              </a>
            </strong>
          </div>

          {/* <div className="preorderDetails">
            <strong>Out Now</strong>
          </div> */}

          <a
            style={{ marginTop: '10px' }}
            href="https://ilovelucius.store/collections/fug-yep"
          >
            ORDER 7”
          </a>

          <p className="artwork">All Artwork by Richard Swift</p>
        </Info>
        <Album source={No2} />
      </Content>
      <Content>
        <Info>
          <Num>No. 1</Num>
          <h2>Lucius</h2>

          <div className="preorderInfo">
            Christmas Time is Here
            <br />
            b/w Keep Me Hanging On
          </div>

          <div className="preorderDetails">
            Produced, recorded, mixed
            <br /> and inspired by Richard Swift
          </div>

          <div className="preorderDetails">
            <strong>
              <a
                href="https://ffm.to/fugyep1"
                target="_blank"
                rel="noopener noreferrer"
                className="singleLine"
              >
                Listen Now
              </a>
            </strong>
          </div>

          <a href="https://ilovelucius.store/collections/fug-yep">
            Order 7&#34;
          </a>

          <p className="artwork">All Artwork by Richard Swift</p>
        </Info>
        <Album source={No1} />
      </Content>

      <VidWrap>
        <Youtube id="-GNBFUp3Cwg" />
      </VidWrap>
    </Box>
  </Header>
);

export default Top;
