import styled from 'styled-components';
import { colors } from '../../constants/theme';

export const Header = styled.header`
  background-color: ${colors.pink};
`;

export const Box = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Num = styled.div`
  display: block;
  font-family: 'ConfidentialPro';
  color: #99343a;
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0.3em;
  text-transform: uppercase;
`;

export const Info = styled.div`
  width: calc(100% / 3);
  color: ${colors.black};

  .artwork {
    font-size: 0.75em;
    margin-top: 10px;
    position: relative;
    top: 40px;
  }

  h4 {
    font-family: 'ConfidentialPro';
    font-size: 1.6em;
    display: block;
    margin-bottom: 0.5em;
    color: ${colors.white};
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  h2 {
    font-family: 'ConfidentialPro';
    font-size: 2.7em;
    display: block;
    margin-bottom: 0.1em;
  }

  .preorderInfo {
    font-family: 'ConfidentialPro';
    font-size: 1.7em;
    margin-bottom: 0.4em;
  }

  .preorderDetails {
    font-size: 0.95em;
    line-height: 1.3;
    margin-bottom: 0.8em;

    .singleLine {
      border: none !important;
      padding: 0;
      margin-bottom: 10px;
    }

    .listenLink {
      border: none;
      padding: 0;
      margin: 15px 0;
      &.singleLine {
        border: none !important;
        padding: 0 !important;
      }
      &:hover {
        background: none;
        color: #99343a;
      }
    }

    strong {
      font-weight: 600;
      font-size: 0.95em;
    }
  }

  a {
    display: inline-block;
    border: 2px solid ${colors.black};
    color: ${colors.black};
    padding: 1em 2em;
    font-size: 0.9em;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    transition: color, background-color 130ms ease-out;
    &:hover {
      color: ${colors.pink};
      background-color: ${colors.black};
    }
  }
`;

export const Album = styled.div`
  flex-grow: 2;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:before {
    content: '';
    background: transparent url(${props => props.source});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 70.78125%;
    display: block;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Release = styled.div`
  text-align: center;
  color: ${colors.black};

  h3 {
    font-family: 'ConfidentialPro';
    font-size: 2.2em;
    display: block;
    margin: 0.6em 0;
  }
`;

export const VidWrap = styled.div`
  margin: 60px auto;
  iframe {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
`;
