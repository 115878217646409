import React from 'react';
import PropTypes from 'prop-types';

const SocialIcon = ({ icon, url, desc }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      <img src={icon} alt={desc} />
    </a>
  );
};

SocialIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default SocialIcon;
