import styled from 'styled-components';
import { colors } from '../../constants/theme';

export const Wrapper = styled.div`
  background-color: ${colors.black};
`;

export const Mission = styled.div`
  width: 100%;
  max-width: 520px;
  margin: 0 auto 50px;
  text-align: center;
  padding: 0 2em;

  @media (max-width: 768px) {
    width: 100%;
  }
  p {
    color: ${colors.white};
    line-height: 1.6;
    margin-bottom: 2em;
  }
`;

export const SocialWrapper = styled.div`
  margin: 40px auto;
  width: 100px;
  display: flex;
  justify-content: space-between;
`;
