import React from 'react';
import Logo from './logo';
import { Wrapper, Mission, SocialWrapper } from './intro.css';
import Instagram from '../../../static/instagram.svg';
import SocialIcon from '../footer/SocialIcon';
import Twitter from '../../../static/twitter.svg';
import Facebook from '../../../static/facebook.svg';

const Intro = () => (
  <Wrapper>
    <Logo />
    <Mission>
      <p>
        The Fug Yep Soundation is a 7” record series created in loving memory of
        our friend &amp; musical hero, Richard Swift. It’s goal, through
        releasing music, is to raise awareness for addiction and bring community
        to those suffering. This series will directly support the Swift family
        and two incredible organizations, MusiCares and Music Support UK who
        will help facilitate this mission.
      </p>

      <p>You are not alone.</p>
    </Mission>
    <SocialWrapper>
      <SocialIcon
        icon={Instagram}
        url="https://www.instagram.com/fugyep/"
        desc="Fug Yep on Instagram"
      />
      <SocialIcon
        icon={Twitter}
        url="https://twitter.com/FugYep"
        desc="Fug Yep on Twitter"
      />
      <SocialIcon
        icon={Facebook}
        url="https://www.facebook.com/FugYep"
        desc="Fug Yep on Facebook"
      />
    </SocialWrapper>
  </Wrapper>
);

export default Intro;
