import styled, { css } from 'styled-components';
import { colors } from '../../constants/theme';

export const Section = styled.div`
  background-color: ${colors.black};
`;

export const Wrapper = styled.div`
  padding: 6rem 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: ${colors.black};
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Col = styled.div`
  width: auto;
  display: flex;

  .artwork {
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: right;
    color: ${colors.white};

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  ${({ icons }) =>
    icons &&
    css`
      align-items: center;
      .musicCare {
        width: 200px;
      }

      img {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: 600px) {
        flex-direction: column !important;
        img {
          margin-right: 0 !important;
          margin-bottom: 20px !important;
        }
      }
    `};

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    ${({ social }) =>
      social &&
      css`
        justify-content: center;
        margin-top: 20px;
      `} img {
      margin-bottom: 20px;
      transform: scale(0.8);
      margin: 0 10px !important;
    }
  }

  img {
    display: block;
    height: auto;
  }

  .social-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .inputField {
    border: none;
    border-bottom: 1px solid ${colors.pink};
    padding: 7px 4px;
    background: none;
    color: ${colors.white};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    position: relative;
    width: 180px;
    top: -1px;
    &:::placeholder {
      color: ${colors.white};
    }
  }

  .inputBtn {
    background-color: ${colors.pink};
    border: none;
    color: ${colors.white};
    padding: 7px 10px;
    font-weight: 600;
    font-size: 0.9em;
    text-transform: uppercase;
  }

  .icons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    a {
      margin-left: 30px;
    }
  }
`;
